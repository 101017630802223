import { RouteComponentProps } from '@reach/router';
import { EntryCollection } from 'contentful';
import * as React from 'react';

import {
  IContentfulPreviewArticle,
  IContentfulPreviewPage,
  getContentfulArticlesByArticleType,
  getContentfulPageBySlug,
} from '../../utils/contentfulClient/contentfulClient';
import {
  capitalize,
  mapContentfulImageToGatsbyImage,
  mapContentfulRichTextToGatsbyRichText,
  mapPrefooterItem,
  useInfoLinks,
  useMenuLinks,
  useVacationBanner,
} from '../../utils/contentfulClient/helpers';

import { ArticlesIndexComponent } from './ArticlesIndexComponent';

interface IArticlesPreviewRouteProps extends RouteComponentProps {
  articleType?: string;
  language?: 'De' | 'En';
}

export const ArticlesIndexPreviewRoute: React.FunctionComponent<IArticlesPreviewRouteProps> = (
  props
) => {
  const [articles, setArticles] = React.useState<
    EntryCollection<IContentfulPreviewArticle>
  >();
  const [page, setPage] = React.useState<
    EntryCollection<IContentfulPreviewPage>
  >();

  if (!props.language) return null;

  const infoLinks = useInfoLinks(props.language);
  const menuLinks = useMenuLinks(props.language);
  const vacationBanner = useVacationBanner();

  React.useEffect(() => {
    if (props.articleType) {
      getContentfulPageBySlug(props.articleType).then(
        (res) => {
          setPage(res);
        },
        (e) => console.error(e)
      );
    }
  }, []);

  React.useEffect(() => {
    if (props.articleType && props.language) {
      getContentfulArticlesByArticleType(
        capitalize(props.articleType),
        props.language
      ).then(
        (res) => {
          setArticles(res);
        },
        (e) => console.error(e)
      );
    }
  }, []);

  if (infoLinks && menuLinks && page && articles && props.articleType) {
    const articlesItems = articles.items;
    const {
      title,
      subHeading,
      featuredImage,
      headerType,
      description,
      sectionId,
      tiles,
      clickToAction,
      clickToActionPrimaryButton
    } = page.items[0].fields;

    return (
      <ArticlesIndexComponent
        language={props.language}
        articles={articlesItems.map((refItem) => ({
          description: { description: refItem.fields.description },
          featuredImage: mapContentfulImageToGatsbyImage(
            refItem.fields.featuredImage,
            {
              width: 1440,
              height: 720,
            }
          ),
          slug: refItem.fields.slug,
          title: refItem.fields.title,
          tags: refItem.fields.tags?.map((tag) => ({
            title: tag.fields.title,
          })),
          linkTitle: refItem.fields.LinkTitle,
        }))}
        menuLinks={menuLinks}
        infoLinks={infoLinks}
        staticLocation={{ pathname: props.articleType }}
        pageContext={{ articleType: props.articleType }}
        title={title}
        subHeading={subHeading}
        featuredImage={
          featuredImage &&
          mapContentfulImageToGatsbyImage(featuredImage, {
            width: 1440,
            height: 1440,
          })
        }
        headerType={headerType}
        description={
          description
            ? {
              description: description,
            }
            : undefined
        }
        sectionId={sectionId}
        pageContent={mapContentfulRichTextToGatsbyRichText(page, 'pageContent')}
        tiles={tiles?.map((item) => mapPrefooterItem(item))}
        clickToAction={
          clickToAction && {
            title: clickToAction.fields.title,
            subTitle: clickToAction.fields.subTitle,
            href: clickToAction.fields.link,
          }
        }
        clickToActionPrimaryButton={
          clickToActionPrimaryButton && {
            title: clickToActionPrimaryButton.fields.title,
            subTitle: clickToActionPrimaryButton.fields.subTitle,
            href: clickToActionPrimaryButton.fields.link,
          }
        }
        vacationBanner={vacationBanner}
      />
    );
  }
  return null;
};
