import { Asset, Entry, EntryCollection, RichTextContent } from 'contentful';

import { IContentfulVacationBanner } from '../types';

function contentfulRequest(
  entry: 'categories' | 'privacyandtos' | 'article' | 'page' | 'vacationBanner',
  slug = '',
  language?: 'De' | 'En',
  articleType?:
    | string
    | undefined
    | 'Reference'
    | 'Karriere'
    | 'News'
    | 'Forderungen'
) {
  return fetch(
    `/.netlify/functions/contentful?entry=${entry}&slug=${slug}&language=${language}&articleType=${articleType}`
  ).then(
    (res) => {
      if (res.ok) return res.json();
      throw new Error('request error');
    },
    (e) => console.error(e)
  );
}
export interface IContentfulPreviewMetaTagField {
  name?: string;
  content?: string;
  property?: string;
}

export interface IContentfulPreviewPage {
  title: string;
  slug: string;
  menuTitle: string;
  subHeading?: string;
  description?: string;
  featuredImage?: Asset;
  headerType: 'Home Hero' | 'Small Hero' | 'Main Hero' | 'Simple';
  sectionId?: string;
  pageContent: RichTextContent;
  meta?: Entry<IContentfulPreviewMetaTagField>;
  tiles?: Entry<IContentfulPreviewTileElement>[];
  clickToAction?: Entry<IContentfulPreviewClickToAction>;
  clickToActionPrimaryButton?: Entry<IContentfulPreviewClickToAction>;
}

export interface IContentfulPreviewCategory {
  title: string;
  slug: string;
  language: 'De' | 'En';
  subHeading?: string;
  menuTitle?: string;
  description?: string;
  featuredImage?: Asset;
  headerType:
    | 'Simple'
    | 'Image to the side'
    | 'Home Hero'
    | 'Small Hero'
    | 'Main Hero';
  sectionId?: string;
  subPages?: Entry<IContentfulPreviewPage>[];
  order: number;
  buttonTitle?: string;
  pageContent: RichTextContent;
  meta?: Entry<IContentfulPreviewMetaTagField>[];
  tiles?: Entry<IContentfulPreviewTileElement>[];
  clickToAction?: Entry<IContentfulPreviewClickToAction>;
}

export async function getContentfulGategoryBySlug(
  slug: string
): Promise<EntryCollection<IContentfulPreviewCategory>> {
  return await contentfulRequest('categories', slug);
}

export async function getContentfulCategories(
  language: 'De' | 'En'
): Promise<EntryCollection<IContentfulPreviewCategory>> {
  return await contentfulRequest('categories', '', language);
}

export interface IContentfulPreviewTileElement {
  title: string;
  slug: string;
  description?: string;
  lInk: string;
  icon: Asset;
  hoverIcon: Asset;
}

export interface IContentfulPreviewClickToAction {
  title: string;
  slug?: string;
  subTitle?: string;
  link?: string;
}

export interface IContentfulPreviewPreFooterBlockk {
  title?: string;
  slug?: string;
  items: Entry<IContentfulPreviewTileElement>[];
}

export interface IContentfulPreviewPrivacyAndToS {
  title: string;
  slug: string;
  language: 'De' | 'En';
  pageContent: string;
  preFooter: Entry<IContentfulPreviewPreFooterBlockk>;
}

export async function getContentfulPrivacyAndToS(
  language: 'De' | 'En'
): Promise<EntryCollection<IContentfulPreviewPrivacyAndToS>> {
  return await contentfulRequest('privacyandtos', '', language);
}

export async function getContentfulPrivacyAndToSBySlug(
  slug: string
): Promise<EntryCollection<IContentfulPreviewPrivacyAndToS>> {
  return await contentfulRequest('privacyandtos', slug);
}

interface IContentfulPreviewTagBlock {
  title: string;
  slug: string;
}

export interface IContentfulPreviewArticle {
  title: string;
  slug: string;
  articleType: 'reference' | 'karriere' | 'news' | 'forderungen';
  featuredImage: Asset;
  LinkTitle: string;
  description: string;
  content?: RichTextContent;
  tags?: Entry<IContentfulPreviewTagBlock>[];
  details?: RichTextContent;
  date?: string;
  meta?: Entry<IContentfulPreviewMetaTagField>[];
}

export async function getContentfulArticlesByArticleType(
  articleType:
    | string
    | undefined
    | 'Reference'
    | 'Karriere'
    | 'News'
    | 'Forderungen',
  language: 'De' | 'En'
): Promise<EntryCollection<IContentfulPreviewArticle>> {
  return await contentfulRequest('article', '', language, articleType);
}

export async function getContentfulArticleBySlug(
  slug: string
): Promise<EntryCollection<IContentfulPreviewArticle>> {
  return await contentfulRequest('article', slug);
}

export async function getContentfulPageBySlug(
  slug: string
): Promise<EntryCollection<IContentfulPreviewPage>> {
  return await contentfulRequest('page', slug);
}

export async function getContentfulVacationBanner(): Promise<
  EntryCollection<IContentfulVacationBanner>
> {
  return await contentfulRequest('vacationBanner');
}
