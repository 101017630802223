import { RouteComponentProps } from '@reach/router';
import { EntryCollection } from 'contentful';
import * as React from 'react';

import {
  IContentfulPreviewPage,
  getContentfulPageBySlug,
} from '../../utils/contentfulClient/contentfulClient';
import {
  mapContentfulImageToGatsbyImage,
  mapContentfulRichTextToGatsbyRichText,
  mapPrefooterItem,
  useInfoLinks,
  useMenuLinks,
  useVacationBanner,
} from '../../utils/contentfulClient/helpers';

import { HomePage } from './HomePage';

interface IHomePagePreviewRouteProps extends RouteComponentProps {
  language?: 'De' | 'En';
}

export const HomePagePreviewRoute: React.FunctionComponent<IHomePagePreviewRouteProps> = (
  props
) => {
  const [page, setPage] = React.useState<
    EntryCollection<IContentfulPreviewPage>
  >();

  React.useEffect(() => {
    const slug = props.language === 'De' ? 'home' : 'en-home';
    getContentfulPageBySlug(slug).then(
      (res) => {
        setPage(res);
      },
      (e) => console.error(e)
    );
  }, []);

  if (!props.language) return null;

  const vacationBanner = useVacationBanner();
  const infoLinks = useInfoLinks(props.language);
  const menuLinks = useMenuLinks(props.language);

  if (infoLinks && menuLinks && page) {
    const pageItem = page.items[0];
    return (
      <HomePage
        menuLinks={menuLinks}
        infoLinks={infoLinks}
        title={pageItem.fields.title}
        headerType={pageItem.fields.headerType}
        menuTitle={pageItem.fields.menuTitle}
        subHeading={pageItem.fields.subHeading}
        language={props.language}
        featuredImage={
          pageItem.fields.featuredImage &&
          mapContentfulImageToGatsbyImage(pageItem.fields.featuredImage, {
            width: 720,
            height: 720,
          })
        }
        staticLocation={{ pathname: '/' }}
        description={{
          description: pageItem.fields.description || '',
        }}
        sectionId={pageItem.fields.sectionId || ''}
        pageContent={mapContentfulRichTextToGatsbyRichText(page, 'pageContent')}
        tiles={
          pageItem.fields?.tiles &&
          pageItem.fields?.tiles.map((item) => mapPrefooterItem(item))
        }
        clickToAction={
          pageItem.fields?.clickToAction && {
            title: pageItem.fields.clickToAction.fields.title,
            subTitle: pageItem.fields.clickToAction.fields.subTitle,
            href: pageItem.fields.clickToAction.fields.link,
          }
        }
        vacationBanner={vacationBanner}
      />
    );
  }
  return null;
};
