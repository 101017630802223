import { RouteComponentProps } from '@reach/router';
import { EntryCollection } from 'contentful';
import * as React from 'react';

import {
  IContentfulPreviewPage,
  getContentfulPageBySlug,
} from '../../utils/contentfulClient/contentfulClient';
import {
  mapContentfulImageToGatsbyImage,
  mapContentfulRichTextToGatsbyRichText,
  mapPrefooterItem,
  useInfoLinks,
  useMenuLinks,
  useVacationBanner,
} from '../../utils/contentfulClient/helpers';

import { ContactPage } from './ContactPage';

interface ISubCategoryPreviewRouteProps extends RouteComponentProps {
  slug?: string;
  language?: 'De' | 'En';
}

export const ContactPagePreviewRoute: React.FunctionComponent<ISubCategoryPreviewRouteProps> = (
  props
) => {
  const [page, setPage] = React.useState<
    EntryCollection<IContentfulPreviewPage>
  >();

  React.useEffect(() => {
    getContentfulPageBySlug(props.slug as string).then(
      (res) => {
        setPage(res);
      },
      (e) => console.error(e)
    );
  }, []);
  if (!props.language || !props.slug) return null;

  const infoLinks = useInfoLinks(props.language);
  const menuLinks = useMenuLinks(props.language);
  const vacationBanner = useVacationBanner();

  if (infoLinks && menuLinks && page) {
    const pageItem = page.items[0];
    return (
      <ContactPage
        menuLinks={menuLinks}
        infoLinks={infoLinks}
        title={pageItem?.fields.title || ''}
        language={props.language}
        subHeading={pageItem?.fields.subHeading}
        headerType={pageItem?.fields.headerType}
        featuredImage={
          pageItem.fields.featuredImage &&
          mapContentfulImageToGatsbyImage(pageItem.fields.featuredImage, {
            width: 1440,
            height: 720,
          })
        }
        description={
          pageItem.fields.description
            ? {
                description: pageItem.fields.description,
              }
            : undefined
        }
        sectionId={pageItem.fields.sectionId}
        pageContent={mapContentfulRichTextToGatsbyRichText(page, 'pageContent')}
        staticLocation={{ pathname: `category/${pageItem.fields.slug}` }}
        tiles={
          pageItem.fields?.tiles &&
          pageItem.fields?.tiles.map((item) => mapPrefooterItem(item))
        }
        clickToAction={
          pageItem.fields?.clickToAction && {
            title: pageItem.fields.clickToAction.fields.title,
            subTitle: pageItem.fields.clickToAction.fields.subTitle,
            href: pageItem.fields.clickToAction.fields.link,
          }
        }
        vacationBanner={vacationBanner}
      />
    );
  }
  return null;
};
