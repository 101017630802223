import { RouteComponentProps } from '@reach/router';
import { EntryCollection } from 'contentful';
import * as React from 'react';

import {
  IContentfulPreviewArticle,
  IContentfulPreviewCategory,
  getContentfulArticlesByArticleType,
  getContentfulGategoryBySlug,
} from '../../utils/contentfulClient/contentfulClient';
import {
  mapContentfulImageToGatsbyImage,
  mapContentfulRichTextToGatsbyRichText,
  mapPrefooterItem,
  useInfoLinks,
  useMenuLinks,
  useVacationBanner,
} from '../../utils/contentfulClient/helpers';
import { ArticlesIndexComponent } from '../ArticlesIndexTemplate/ArticlesIndexComponent';

import { CategoryComponent } from './CategoryComponent';

interface ICategoryPreviewRouteProps extends RouteComponentProps {
  slug?: string;
  language?: 'De' | 'En';
}

export const CategoryPreviewRoute: React.FunctionComponent<ICategoryPreviewRouteProps> = (
  props
) => {
  const [category, setCategory] = React.useState<
    EntryCollection<IContentfulPreviewCategory>
  >();

  const [references, setReferences] = React.useState<
    EntryCollection<IContentfulPreviewArticle>
  >();
  if (!props.language || !props.slug) return null;

  React.useEffect(() => {
    if (props.slug)
      getContentfulGategoryBySlug(props.slug).then(
        (res) => {
          if (res.items[0].fields.order !== 3) {
            setCategory(res);
          } else {
            if (props.language) {
              getContentfulArticlesByArticleType(
                'Reference',
                props.language
              ).then(
                (refrencesResponse) => {
                  setReferences(refrencesResponse);
                },
                (e) => console.error(e)
              );
            }
          }
        },
        (e) => console.error(e)
      );
  }, []);

  const infoLinks = useInfoLinks(props.language);
  const menuLinks = useMenuLinks(props.language);
  const vacationBanner = useVacationBanner();

  if (category && menuLinks && infoLinks) {
    const categoryItem = category.items[0];
    return (
      <CategoryComponent
        title={categoryItem.fields.title}
        language={categoryItem.fields.language}
        sectionId={categoryItem.fields.sectionId || ''}
        subHeading={categoryItem.fields.subHeading || ''}
        description={{ description: categoryItem.fields.description || '' }}
        headerType={categoryItem.fields.headerType}
        menuLinks={menuLinks}
        infoLinks={infoLinks}
        featuredImage={
          categoryItem.fields.featuredImage &&
          mapContentfulImageToGatsbyImage(categoryItem.fields.featuredImage, {
            width: 720,
            height: 720,
          })
        }
        meta={categoryItem.fields.meta?.map((m) => ({ ...m.fields }))}
        order={categoryItem.fields.order}
        pageContent={mapContentfulRichTextToGatsbyRichText(
          category,
          'pageContent'
        )}
        staticLocation={{
          pathname: categoryItem.fields.slug,
        }}
        tiles={
          categoryItem.fields?.tiles
            ? categoryItem.fields?.tiles.map((item) => mapPrefooterItem(item))
            : null
        }
        clickToAction={
          categoryItem.fields?.clickToAction && {
            title: categoryItem.fields.clickToAction.fields.title,
            subTitle: categoryItem.fields.clickToAction.fields.subTitle,
            href: categoryItem.fields.clickToAction.fields.link,
          }
        }
        vacationBanner={vacationBanner}
      />
    );
  }

  if (references && menuLinks && infoLinks) {
    const referencesItems = references.items;
    return (
      <ArticlesIndexComponent
        title="Referenzen"
        language={props.language}
        articles={referencesItems.map((refItem) => ({
          description: { description: refItem.fields.description },
          featuredImage: mapContentfulImageToGatsbyImage(
            refItem.fields.featuredImage,
            {
              width: 1440,
              height: 720,
            }
          ),
          slug: refItem.fields.slug,
          title: refItem.fields.title,
          tags: refItem.fields.tags?.map((tag) => ({
            title: tag.fields.title,
          })),
        }))}
        menuLinks={menuLinks}
        infoLinks={infoLinks}
        staticLocation={{ pathname: 'referenzen' }}
        pageContext={{
          articleType: 'Reference',
        }}
      />
    );
  }
  return null;
};
