import { RouteComponentProps } from '@reach/router';
import { EntryCollection } from 'contentful';
import * as React from 'react';

import {
  IContentfulPreviewPrivacyAndToS,
  getContentfulPrivacyAndToSBySlug,
} from '../../utils/contentfulClient/contentfulClient';
import {
  mapPrefooterItem,
  markdownToHtml,
  useInfoLinks,
  useMenuLinks,
  useVacationBanner,
} from '../../utils/contentfulClient/helpers';

import { PrivacyAndToSComponent } from './PrivacyAndToSComponent';

interface IPrivacyAndToSPreviewRouteProps extends RouteComponentProps {
  slug?: string;
  language?: 'De' | 'En';
}

export const PrivacyAndToSPreviewRoute: React.FunctionComponent<IPrivacyAndToSPreviewRouteProps> = (
  props
) => {
  const [privacyData, setPrivacyData] = React.useState<
    EntryCollection<IContentfulPreviewPrivacyAndToS>
  >();

  if (!props.language || !props.slug) return null;

  const infoLinks = useInfoLinks(props.language);
  const menuLinks = useMenuLinks(props.language);
  const vacationBanner = useVacationBanner();

  React.useEffect(() => {
    if (props.slug) {
      getContentfulPrivacyAndToSBySlug(props.slug).then(
        (res) => {
          setPrivacyData(res);
        },
        (e) => console.error(e)
      );
    }
  }, []);

  if (privacyData && menuLinks && infoLinks) {
    return (
      <PrivacyAndToSComponent
        menuLinks={menuLinks}
        infoLinks={infoLinks}
        language={props.language}
        title={privacyData.items[0].fields.title}
        slug={privacyData.items[0].fields.slug}
        html={markdownToHtml(privacyData.items[0].fields.pageContent)}
        prefooterItems={privacyData.items[0].fields.preFooter.fields.items.map(
          mapPrefooterItem
        )}
        vacationBanner={vacationBanner}
      />
    );
  }

  return null;
};
