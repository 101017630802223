import { Asset, Entry, EntryCollection } from 'contentful';
import { Layout } from 'gatsby-plugin-image';
import { RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import { marked } from 'marked';
import { useEffect, useState } from 'react';

import { IInfoLink, IMenuLink } from '../../components';
import { IContentfulTileElement } from '../fragments/tileElementFragment';
import { RichTextElements } from '../fragments/types';
import { IContentfulVacationBanner, ICustomGatsbyImageData } from '../types';

import {
  IContentfulPreviewArticle,
  IContentfulPreviewCategory,
  IContentfulPreviewPage,
  IContentfulPreviewPrivacyAndToS,
  IContentfulPreviewTileElement,
  getContentfulCategories,
  getContentfulPrivacyAndToS,
  getContentfulVacationBanner,
} from './contentfulClient';

export function mapContentfulImageToGatsbyImage(
  contentfulImage: Asset,
  options?: {
    width?: number;
    height?: number;
    layout?: Layout;
    sizes?: string;
  }
): ICustomGatsbyImageData {
  const aspectRatio = contentfulImage.fields.file.details.image
    ? contentfulImage.fields.file.details.image.height /
      contentfulImage.fields.file.details.image.width
    : 1;
  return {
    title: contentfulImage.fields.title,
    description: contentfulImage.fields.description,
    resize: {
      aspectRatio: aspectRatio,
    },
    gatsbyImageData: {
      layout: options?.layout || 'constrained',
      width: options?.width || 380,
      height: options?.height
        ? options?.height
        : aspectRatio
        ? aspectRatio * 380
        : 380,
      images: {
        sources: [
          {
            srcSet: `https:${contentfulImage.fields.file.url}`,
            type: 'image/webp',
          },
        ],
        fallback: {
          src: `https:${contentfulImage.fields.file.url}`,
          sizes: options?.sizes,
        },
      },
    },
  };
}

export function capitalize(
  str?: string
): string | undefined | 'Reference' | 'Karriere' | 'News' | 'Forderungen' {
  if (!str) return;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function mapContentfulRichTextToGatsbyRichText(
  entry: EntryCollection<
    | IContentfulPreviewPage
    | IContentfulPreviewArticle
    | IContentfulPreviewCategory
  >,
  richTextField: 'pageContent' | 'content' | 'details'
): RenderRichTextData<RichTextElements> {
  const entryItem = entry.items[0];
  const content = entryItem.fields[richTextField].content;
  const raw = JSON.stringify(entryItem.fields[richTextField]);

  return {
    raw,
    references:
      content
        ?.filter((c) =>
          [
            'embedded-asset-block',
            'embedded-entry-block',
            'asset-hyperlink',
          ].includes(c.nodeType)
        )
        .map((c) => c.data.target?.sys.id)
        .map(
          (id) =>
            entry.includes?.Entry?.find((entry) => entry.sys.id === id) ||
            entry.includes?.Asset?.find((entry) => entry.sys.id === id)
        )
        .filter(Boolean)
        .map((entryToMap) => {
          return mapEntryToRichTextReference(entryToMap);
        }) || [],
  };
}

function mapEntryToRichTextReference(entry: Entry<any>) {
  const __typename = entry.sys.contentType
    ? `Contentful${capitalize(entry.sys.contentType.sys.id)}`
    : 'ContentfulAsset';
  const commonFields = {
    contentful_id: entry.sys.id,
    __typename,
    title: entry.fields.title,
    slug: entry.fields.slug,
  };

  switch (__typename) {
    case 'ContentfulAsset':
      return {
        ...commonFields,
        gatsbyImageData: mapContentfulImageToGatsbyImage(entry, {
          width: 1440,
          sizes:
            '(max-width:767px) 450px, (max-width:1023px) 768px, (max-width: 1439px) 1024px,1440px',
        }),
      };
    case 'ContentfulVideoBlock':
      return {
        ...commonFields,
        videoId: entry.fields.videoId,
        description: {
          description: entry.fields.description,
        },
      };
    case 'ContentfulReviewBlock':
      return {
        ...commonFields,
        isPartner: entry.fields.isPartner,
        reviews: entry.fields.reviews.map((review) => ({
          name: review.fields.name,
          occupation: review.fields.occupation,
          link: review.fields.link,
          featuredImage: mapContentfulImageToGatsbyImage(
            review.fields.featuredImage,
            {
              width: 464,
              height: 464,
              sizes:
                '(max-width:1023px) 388px, (max-width: 1439px) 464px, (max-width: 1919px) 436px',
            }
          ),
          content: {
            content: review.fields.content,
          },
        })),
      };
    case 'ContentfulImageBlock':
      return {
        ...commonFields,
        images: entry.fields.images?.map((image) => ({
          gatsbyImageData: mapContentfulImageToGatsbyImage(image),
          title: image.fields.title,
        })),
      };
    case 'ContentfulTable':
      return {
        ...commonFields,
        content: { content: entry.fields.content },
      };
    case 'ContentfulTextBlock':
      return {
        ...commonFields,
        content: { content: entry.fields.content },
        sideBySide: entry.fields.sideBySide,
      };
    case 'ContentfulHighlight':
      return {
        ...commonFields,
        subHeading: entry.fields.subHeading,
        content: { content: entry.fields.content },
        featuredImage:
          entry.fields.featuredImage &&
          mapContentfulImageToGatsbyImage(entry.fields.featuredImage, {
            width: 960,
            height: 960,
            sizes:
              '(max-width: 767px) 450px, (max-width:1023px) 768px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 960px',
          }),
        link: {
          title: entry.fields.link?.fields.title,
          slug: entry.fields.link?.fields.slug,
          url: entry.fields.link?.fields.url,
        },
        orientation: entry.fields.orientation,
        teint: entry.fields.teint,
      };
    case 'ContentfulTrustBlock':
      return {
        ...commonFields,
        elements: entry.fields.icons.map((icon) =>
          mapContentfulImageToGatsbyImage(icon, {
            width: 426,
            height: 426,
            sizes:
              '(max-width: 767px) 162px, (max-width:1023px) 180px, (max-width: 1439px) 216px, (max-width: 1919px) 320px, 426px',
          })
        ),
      };
    case 'ContentfulSection':
      return {
        ...commonFields,
        description: { description: entry.fields.description },
        links: entry.fields.links?.map((link) => ({
          title: link.fields.title,
          slug: link.fields.slug,
          url: link.fields.url,
        })),
        image:
          entry.fields.image &&
          mapContentfulImageToGatsbyImage(entry.fields.image, {
            width: 960,
            height: 960,
            sizes:
              '(max-width: 767px) 450px, (max-width:1023px) 768px, (max-width: 1439px) 512px, (max-width: 1919px) 720px, 960px',
          }),
        sideBySIde: entry.fields.sideBySIde,
      };
    case 'ContentfulBenefitsBlock':
      return {
        ...commonFields,
        elements: entry.fields.elements?.map((element) => ({
          title: element.fields.title,
          slug: element.fields.slug,
          image:
            element.fields.image &&
            mapContentfulImageToGatsbyImage(element.fields.image, {
              width: 688,
              height: 688,
              sizes:
                '(max-width: 767px) 370x, (max-width:1023px) 688px, (max-width: 1439px) 220px, (max-width: 1919px) 344px, 486px',
            }),
          link: element.fields.link,
        })),
      };
    case 'ContentfulTeamBlock':
      return {
        ...commonFields,
        members: entry.fields.members?.map((member) => ({
          name: member.fields.name,
          slug: member.fields.slug,
          position: member.fields.position,
          eMail: member.fields.eMail,
          phoneNumber: member.fields.phoneNumber,
          image:
            member.fields.image &&
            mapContentfulImageToGatsbyImage(member.fields.image, {
              width: 387,
              height: 387,
              sizes:
                '(max-width: 767px) 387px , (max-width:1023px) 335px, (max-width: 1439px) 298px ,350px',
            }),
        })),
      };
    case 'ContentfulStepsBlock':
      return {
        ...commonFields,
        elements: entry.fields.elements?.map((element) => ({
          title: element.fields.title,
          slug: element.fields.slug,
          description: {
            description: element.fields.description,
          },
          image:
            element.fields.image &&
            mapContentfulImageToGatsbyImage(element.fields.image, {
              width: 387,
              height: 387,
              sizes:
                '(max-width: 767px) 387px, (max-width:1023px) 335px, (max-width: 1439px) 214px, 318px',
            }),
        })),
      };
    case 'ContentfulSliderBlock':
      return {
        ...commonFields,
        titleSize: entry.fields.titleSize,
        paragraph: {
          paragraph: entry.fields.paragraph,
        },
        elementType: entry.fields.elementType,
        elements: entry.fields.elements?.map((element) => ({
          // common fields
          title: element.fields.title,
          slug: element.fields.slug,
          description: {
            description: element.fields.description,
          },
          // cardBlock fields
          image:
            element.fields.image &&
            mapContentfulImageToGatsbyImage(element.fields.image),
          // TODO: check if should be `tags`
          tagList: element.fields.tagList?.map((tag) => ({
            title: tag.fields.title,
            slug: tag.fields.slug,
          })),
          type: element.fields.type,
          // videoBlock fields
          videoId: element.fields.videoId,
          internalLink: {
            title: element.fields.link.fields.title,
            url: element.fields.link.fields.url,
          },
        })),
        images: entry.fields.images?.map((image) =>
          mapContentfulImageToGatsbyImage(image, {
            width: 768,
            height: 700,
          })
        ),
      };
    case 'ContentfulPreFooterBlock':
      return {
        ...commonFields,
        items: entry.fields.items?.map(mapPrefooterItem),
      };
    case 'ContentfulIconGridBlock':
    case 'ContentfulIconsGridBlock':
      return {
        ...commonFields,
        elements: entry.fields.elements?.map((element) => ({
          title: element.fields.title,
          slug: element.fields.slug,
          description: {
            description: element.fields.description,
          },
          icon: element.fields.icon && {
            title: element.fields.icon.fields.title,
            file: { url: element.fields.icon.fields.file.url },
            description: {
              description: element.fields.icon.fields.description,
            },
          },
        })),
      };
    case 'ContentfulAccordionBlock':
      return {
        ...commonFields,
        description: {
          description: entry.fields.description,
        },
        tanned: entry.fields.tanned,
        elements: entry.fields.elements
          ?.filter((element) => !!element.fields)
          .map((element) => ({
            title: element.fields.title,
            slug: element.fields.slug,
            textContent: element.fields.textContent && {
              raw: JSON.stringify(element.fields.textContent),
              references: element.fields.textContent?.content
                .filter((c) =>
                  [
                    'embedded-asset-block',
                    'embedded-entry-block',
                    'asset-hyperlink',
                  ].includes(c.nodeType)
                )
                .map((c) => {
                  return mapEntryToRichTextReference(c);
                }),
            },
            featuredImage:
              element.fields.featuredImage &&
              mapContentfulImageToGatsbyImage(element.fields.featuredImage, {
                width: 387,
                sizes: '(max-width: 767px) 370x, 255px',
              }),
            links: element.fields.links?.map((link) => ({
              title: link.fields.title,
              slug: link.fields.slug,
              url: link.fields.url,
            })),
            cardsList: element.fields.cardsList?.map((card) => ({
              // common fields
              title: card.fields.title,
              slug: card.fields.slug,
              description: {
                description: card.fields.description,
              },
              // cardBlock fields
              image:
                card.fields.image &&
                mapContentfulImageToGatsbyImage(card.fields.image),
              linkTitle: card.fields.linkTitle,
              // TODO: check if should be `tags`
              tagList: card.fields.tagList.map((tag) => ({
                title: tag.fields.title,
                slug: tag.fields.slug,
              })),
              type: card.fields.type,
              articleType: card.fields.articleType,
            })),
            table: {
              title: element.fields.table?.fields.title,
              slug: element.fields.table?.fields.slug,
              content: { content: element.fields.table?.fields.content },
            },
            smallText: element.fields.smallText,
          })),
      };
    case 'ContentfulFactsBlock':
      return {
        ...commonFields,
        elements: entry.fields.elements?.map((element) => ({
          title: element.fields.title,
          slug: element.fields.slug,
          value: element.fields.value,
          description: {
            description: element.fields.description,
          },
        })),
      };
    case 'ContentfulCatalogue':
      return {
        ...commonFields,
        description: {
          subHeading: entry.fields.subHeading,
        },
        icons: entry.fields.icons?.map((icon) => ({
          file: { url: icon.fields.file.url },
          title: icon.fields.title,
        })),
        featuredImage:
          entry.fields.featuredImage &&
          mapContentfulImageToGatsbyImage(entry.fields.featuredImage, {
            width: 1024,
            height: 380,
          }),
      };
    case 'ContentfulVideosContainer':
      return {
        ...commonFields,
        elements: entry.fields.elements?.map((element) => ({
          title: element.fields.title,
          slug: element.fields.slug,
          videoId: element.fields.videoId,
          description: {
            description: element.fields.description,
          },
          internalLink: element.fields.link && {
            title: element.fields.link.fields.title,
            url: element.fields.link.fields.url,
          },
        })),
      };
    case 'ContentfulProductDetailsBlock': {
      const __typename = `Contentful${capitalize(
        entry.fields.details?.sys.contentType.sys.id
      )}`;
      if (__typename === 'ContentfulList') {
        return {
          ...commonFields,
          subTitle: entry.fields.subTitle,
          image:
            entry.fields.image &&
            mapContentfulImageToGatsbyImage(entry.fields.image),
          isDecorated: entry.fields.isDecorated,
          details: {
            __typename,
            title: entry.fields.details.fields.title,
            slug: entry.fields.details.fields.slug,
            elements: entry.fields.details.fields.elements.map((element) => ({
              title: element.fields.title,
              slug: element.fields.slug,
              content: { content: element.fields.content },
            })),
          },
        };
      } else if (__typename === 'ContentfulTable') {
        return {
          ...commonFields,
          subTitle: entry.fields.subTitle,
          image:
            entry.fields.image &&
            mapContentfulImageToGatsbyImage(entry.fields.image),
          isDecorated: entry.fields.isDecorated,
          details: {
            __typename,
            title: entry.fields.details.fields.title,
            slug: entry.fields.details.fields.slug,
            content: { content: entry.fields.details.fields.content },
          },
        };
      } else
        return {
          ...commonFields,
          subTitle: entry.fields.subTitle,
          image:
            entry.fields.image &&
            mapContentfulImageToGatsbyImage(entry.fields.image),
          isDecorated: entry.fields.isDecorated,
        };
    }

    case 'ContentfulServiceToolsBlock':
      return {
        ...commonFields,
        paragraph: {
          paragraph: entry.fields.paragraph,
        },
        elements: entry.fields.elements?.map((element) => ({
          title: element.fields.title,
          slug: element.fields.slug,
          description: {
            description: element.fields.description,
          },
          image:
            element.fields.image &&
            mapContentfulImageToGatsbyImage(element.fields.image, {
              width: 720,
              height: 720,
              sizes:
                '(max-width: 767px) 450px, (max-width:1023px) 768px, (max-width: 1439px) 512px, 720px',
            }),
        })),
      };
    case 'ContentfulPartnershipImageBlock':
      return {
        ...commonFields,
        image:
          entry.fields.image &&
          mapContentfulImageToGatsbyImage(entry.fields.image, {
            width: 450,
          }),
        description: {
          description: entry.fields.description,
        },
      };
    case 'ContentfulWidgetBlock':
      return {
        ...commonFields,
        title: entry.fields.title,
        slug: entry.fields.slug,
        widgetType: entry.fields.widgetType,
      };
    case 'ContentfulInfoTabsBlock':
      return {
        ...commonFields,
        tabs: entry.fields.tabs.map((tab) => ({
          title: tab.fields.title,
          slug: tab.fields.slug,
          elements: tab.fields.elements.map((element) => ({
            title: element.fields.title,
            slug: element.fields.slug,
            textContent: element.fields.textContent && {
              raw: JSON.stringify(element.fields.textContent),
              references: element.fields.textContent?.content
                .filter((c) =>
                  [
                    'embedded-asset-block',
                    'embedded-entry-block',
                    'asset-hyperlink',
                  ].includes(c.nodeType)
                )
                .map((c) => {
                  return mapEntryToRichTextReference(c);
                }),
            },
            featuredImage:
              element.fields.featuredImage &&
              mapContentfulImageToGatsbyImage(element.fields.featuredImage, {
                width: 203,
                height: 203,
              }),
            links: element.fields.links?.map((link) => ({
              title: link.fields.title,
              slug: link.fields.slug,
              url: link.fields.url,
            })),
            cardsList: element.fields.cardsList?.map((card) => ({
              // common fields
              title: card.fields.title,
              slug: card.fields.slug,
              description: {
                description: card.fields.description,
              },
              // cardBlock fields
              image:
                card.fields.image &&
                mapContentfulImageToGatsbyImage(card.fields.image),
              linkTitle: card.fields.linkTitle,
              // TODO: check if should be `tags`
              tagList: card.fields.tagList.map((tag) => ({
                title: tag.fields?.title,
                slug: tag.fields?.slug,
              })),
              type: card.fields.type,
              articleType: card.fields.articleType,
            })),
            table: {
              title: element.fields.table?.fields.title,
              slug: element.fields.table?.fields.slug,
              content: {
                content: element.fields.table?.fields.content,
              },
            },
            smallText: element.fields.smallText,
          })),
        })),
      };
    case 'ContentfulMapBlock':
      return {
        ...commonFields,
        title: entry.fields.title || '',
        address: entry.fields.address,
      };
    case 'ContentfulCardsContainer':
      return {
        ...commonFields,
        title: entry.fields.title,
        slug: entry.fields.slug,
        elements: entry.fields.elements.map((card) => ({
          title: card.fields.title,
          slug: card.fields.slug,
          description: {
            description: card.fields.description,
          },
          image:
            card.fields.image &&
            mapContentfulImageToGatsbyImage(card.fields.image),
          internalLink: {
            title: card.fields.link.fields.title,
            url: card.fields.link.fields.url,
          },
          tags: card.fields.tagList.map((tag) => ({
            title: tag.fields.title,
            slug: tag.fields.slug,
          })),
        })),
      };
    default:
      return {
        ...commonFields,
      };
  }
}

export function mapContentfulCategoriesToMenuLink(
  categoriesResponse: EntryCollection<IContentfulPreviewCategory>
): IMenuLink[] {
  return categoriesResponse.items.map((item) => ({
    title: item.fields.title,
    slug: item.fields.slug,
    language: item.fields.language,
    btnTitle: item.fields.buttonTitle,
    menuTitle: item.fields.menuTitle,
    order: item.fields.order,
    subPages:
      item.fields.subPages?.map((sp) => ({
        title: sp.fields.title,
        menuTitle: sp.fields.menuTitle,
        slug: sp.fields.slug,
        featuredImage:
          sp.fields.featuredImage &&
          mapContentfulImageToGatsbyImage(sp.fields.featuredImage),
      })) || [],
  }));
}

export function mapContentfulPrivacyAndToSToInfoLinks(
  privacyAndToSResponse: EntryCollection<IContentfulPreviewPrivacyAndToS>
): IInfoLink[] {
  return privacyAndToSResponse.items.map((item) => ({
    title: item.fields.title,
    url: item.fields.slug,
    language: item.fields.language,
  }));
}

export function mapContentfulVacationBanner(
  vacationBannerResponse: EntryCollection<IContentfulVacationBanner>
): IContentfulVacationBanner {
  return {
    content: {
      raw: JSON.stringify(vacationBannerResponse.items[0].fields.content),
    },
    eventEndingDate: vacationBannerResponse.items[0].fields.eventEndingDate,
    online: vacationBannerResponse.items[0].fields.online,
    eventType: vacationBannerResponse.items[0].fields.eventType,
  };
}

export function markdownToHtml(md: string): string {
  return marked.parse(md);
}

export function useMenuLinks(language: 'De' | 'En'): IMenuLink[] | undefined {
  const [menuLinks, setMenuLinks] = useState<IMenuLink[]>();

  useEffect(() => {
    getContentfulCategories(language).then(
      (res) => {
        setMenuLinks(mapContentfulCategoriesToMenuLink(res));
      },
      (e) => console.error(e)
    );
  }, []);

  return menuLinks;
}

export function useInfoLinks(language: 'De' | 'En'): IInfoLink[] | undefined {
  const [infoLinks, setInfoLinks] = useState<IInfoLink[]>();

  useEffect(() => {
    getContentfulPrivacyAndToS(language).then(
      (res) => {
        setInfoLinks(mapContentfulPrivacyAndToSToInfoLinks(res));
      },
      (e) => console.error(e)
    );
  }, []);

  return infoLinks;
}

export function useVacationBanner(): IContentfulVacationBanner | undefined {
  const [data, setData] = useState<IContentfulVacationBanner>();

  useEffect(() => {
    getContentfulVacationBanner().then(
      (res) => {
        setData(mapContentfulVacationBanner(res));
      },
      (e) => console.error(e)
    );
  }, []);
  return data;
}

export function mapPrefooterItem(
  item: Entry<IContentfulPreviewTileElement>
): IContentfulTileElement {
  return {
    title: item.fields?.title,
    slug: item.fields?.slug,
    description: { description: item.fields?.description || '' },
    link: item.fields?.lInk,
    icon: {
      title: item.fields?.icon.fields.title,
      file: {
        url: item.fields?.icon.fields.file.url,
      },
    },
    hoverIcon: {
      title: item.fields?.hoverIcon.fields.title,
      file: {
        url: item.fields?.hoverIcon.fields.file.url,
      },
    },
  };
}
