import { RouteComponentProps, navigate } from '@reach/router';
import { EntryCollection } from 'contentful';
import * as React from 'react';

import {
  IContentfulPreviewPage,
  getContentfulPageBySlug,
} from '../../utils/contentfulClient/contentfulClient';
import {
  mapContentfulImageToGatsbyImage,
  mapContentfulRichTextToGatsbyRichText,
  mapPrefooterItem,
  useInfoLinks,
  useMenuLinks,
  useVacationBanner,
} from '../../utils/contentfulClient/helpers';

import { SubCategoryComponent } from './SubCategoryComponent';

interface ISubCategoryPreviewRouteProps extends RouteComponentProps {
  slug?: string;
  language?: 'De' | 'En';
}

export const SubCategoryPreviewRoute: React.FunctionComponent<ISubCategoryPreviewRouteProps> = (
  props
) => {
  const [page, setPage] = React.useState<
    EntryCollection<IContentfulPreviewPage>
  >();

  if (!props.language || !props.slug) return null;

  const infoLinks = useInfoLinks(props.language);
  const menuLinks = useMenuLinks(props.language);
  const vacationBanner = useVacationBanner();
  React.useEffect(() => {
    if (!props.slug) {
      return;
    } else if (props.slug === 'forderungen') {
      navigate(`/preview/${props.language}/forderungen`, { replace: true });
    } else if (props.slug === 'news') {
      navigate(`/preview/${props.language}/news`, { replace: true });
    } else if (props.slug === 'jobs-und-ausbildungen') {
      navigate(`/preview/${props.language}/jobs-und-ausbildungen`, { replace: true });
    } else if (props.slug === 'home' || props.slug === 'en-home') {
      navigate(`/preview/${props.language}`, { replace: true });
    } else if (props.slug === 'kontakt' || props.slug === 'contact') {
      navigate(`/preview/${props.language}/kontakt`, { replace: true });
    } else {
      getContentfulPageBySlug(props.slug).then(
        (res) => {
          setPage(res);
        },
        (e) => console.error(e)
      );
    }
  }, []);

  if (infoLinks && menuLinks && page) {
    const pageItem = page.items[0];
    return (
      <SubCategoryComponent
        menuLinks={menuLinks}
        infoLinks={infoLinks}
        title={pageItem.fields.title}
        language={props.language}
        subHeading={pageItem.fields.subHeading}
        headerType={pageItem.fields.headerType}
        featuredImage={
          pageItem.fields.featuredImage &&
          mapContentfulImageToGatsbyImage(pageItem.fields.featuredImage, {
            width: 1440,
            height: 1440,
          })
        }
        description={
          pageItem.fields.description
            ? {
              description: pageItem.fields.description,
            }
            : undefined
        }
        sectionId={pageItem.fields.sectionId}
        pageContent={mapContentfulRichTextToGatsbyRichText(page, 'pageContent')}
        staticLocation={{ pathname: `category/${pageItem.fields.slug}` }}
        tiles={
          pageItem.fields?.tiles &&
          pageItem.fields?.tiles.map((item) => mapPrefooterItem(item))
        }
        clickToAction={
          pageItem.fields?.clickToAction && {
            title: pageItem.fields.clickToAction.fields.title,
            subTitle: pageItem.fields.clickToAction.fields.subTitle,
            href: pageItem.fields.clickToAction.fields.link,
          }
        }
        clickToActionPrimaryButton={
          pageItem.fields?.clickToActionPrimaryButton && {
            title: pageItem.fields.clickToActionPrimaryButton.fields.title,
            subTitle: pageItem.fields.clickToActionPrimaryButton.fields.subTitle,
            href: pageItem.fields.clickToActionPrimaryButton.fields.link,
          }
        }
        vacationBanner={vacationBanner}
      />
    );
  }
  return null;
};
