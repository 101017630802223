import { RouteComponentProps } from '@reach/router';
import { EntryCollection } from 'contentful';
import * as React from 'react';

import {
  IContentfulPreviewArticle,
  getContentfulArticleBySlug,
} from '../../utils/contentfulClient/contentfulClient';
import {
  mapContentfulImageToGatsbyImage,
  mapContentfulRichTextToGatsbyRichText,
  useInfoLinks,
  useMenuLinks,
  useVacationBanner,
} from '../../utils/contentfulClient/helpers';

import { ArticleComponent } from './ArticleComponent';

interface IArticlePreviewRouteProps extends RouteComponentProps {
  slug?: string;
  language?: 'De' | 'En';
}

export const ArticlePreviewRoute: React.FunctionComponent<IArticlePreviewRouteProps> = (
  props
) => {
  const [article, setArticle] = React.useState<
    EntryCollection<IContentfulPreviewArticle>
  >();

  if (!props.language || !props.slug) return null;

  const infoLinks = useInfoLinks(props.language);
  const menuLinks = useMenuLinks(props.language);
  const vacationBanner = useVacationBanner();

  React.useEffect(() => {
    if (props.slug) {
      getContentfulArticleBySlug(props.slug).then(
        (res) => {
          setArticle(res);
        },
        (e) => console.error(e)
      );
    }
  }, []);

  if (infoLinks && menuLinks && article) {
    const articleItem = article.items[0];
    return (
      <ArticleComponent
        infoLinks={infoLinks}
        menuLinks={menuLinks}
        language={props.language}
        title={articleItem.fields.title}
        mainPost={{
          title: articleItem.fields.title,
          featuredImage: mapContentfulImageToGatsbyImage(
            articleItem.fields.featuredImage,
            {
              width: 1440,
              height: 720,
            }
          ),
          tags: articleItem.fields.tags?.map((tag) => ({
            title: tag.fields.title,
          })),
        }}
        content={mapContentfulRichTextToGatsbyRichText(article, 'content')}
        details={
          articleItem.fields.details &&
          mapContentfulRichTextToGatsbyRichText(article, 'details')
        }
        staticLocation={{
          pathname: `${articleItem.fields.articleType}/${articleItem.fields.slug}`,
        }}
        articleType={articleItem.fields.articleType}
        vacationBanner={vacationBanner}
      />
    );
  }
  return null;
};
