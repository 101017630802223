import { Router } from '@reach/router';
import * as React from 'react';

import { ContactPagePreviewRoute } from '../../components/ContactPage/ContactPagePreview';
import { HomePagePreviewRoute } from '../../components/HomePage/HomePagePreviewRoute';
import { ArticlePreviewRoute } from '../../templates/ArticleTemplate/ArticlePreviewRoute';
import { ArticlesIndexPreviewRoute } from '../../templates/ArticlesIndexTemplate/ArticlesIndexPreviewRoute';
import { CategoryPreviewRoute } from '../../templates/CategoryTemplate/CategoryPreviewRoute';
import { PrivacyAndToSPreviewRoute } from '../../templates/PrivacyAndToSTemplate/PrivacyAndToSPreviewRoute';
import { SubCategoryPreviewRoute } from '../../templates/SubCategoryTemplate/SubCategoryPreviewRouter';

const Preview: React.FunctionComponent = () => {
  const [shouldRender, setShouldRender] = React.useState(false);

  React.useEffect(() => {
    const allowedHosts = [
      'localhost:8888',
      'localhost:8000',
      'epic-hamilton-69b336.netlify.app',
    ];
    setShouldRender(allowedHosts.includes(window.location.host));
  }, []);

  return shouldRender ? (
    <Router basepath="/preview">
      <HomePagePreviewRoute path="/:language" />

      <CategoryPreviewRoute path="categories/:language/:slug" />
      <SubCategoryPreviewRoute path="subcategories/:language/:slug" />

      <ArticlesIndexPreviewRoute path=":language/:articleType" />
      <ArticlePreviewRoute path=":language/:articleType/:slug" />

      <PrivacyAndToSPreviewRoute path="privacy/:language/:slug" />

      <ContactPagePreviewRoute path=":language/:slug" />
    </Router>
  ) : null;
};

export default Preview;
